import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo"

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
}

export default NotFoundPage;
